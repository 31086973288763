<template>
  <div class="list-container">
    <div class="cover">
      <div class="cover-content">
        <h1>尊敬的客户-{{username}}：</h1>
        <p>您好，</p>
        <p>欢迎您使用客户对账系统，若有疑问可进行留言反馈,也可以直接致电公司投诉电话。</p>
        <p><span v-if="webTell">座机--{{webTell}}</span><span v-if="webMobile">&nbsp;&nbsp;&nbsp;&nbsp;手机--{{webMobile}}</span></p>
      </div>

    </div>
    <!--  表格-->
    <div class="table-area">
      <table>
        <thead>
        <tr>
          <td>公司名称</td>
          <td>业务员姓名</td>
          <td>业务员电话</td>
          <td>余额(元)</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in list" :key="index" @click="$router.push({
        path:'detail',
        query:{
          pkCorp:item.pkCorp,
          custname:item.custname,
        }
        })">
          <td style="text-decoration: underline">{{ item.unitname }}</td>
          <td>{{ item.ywyxm }}</td>
          <td>{{ item.ext2 }}</td>
          <td>¥：{{ item.yue }}</td>
        </tr>
        </tbody>
      </table>
      <el-empty v-if="list.length==0" description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  data() {
    return {
      list: [],
      webMobile:'',
      webTell:'',
      username:'',
    };
  },
  components: {},
  props: {},
  created() {
  },
  mounted() {
    this.webMobile = this.$store.state.webMobile
    this.webTell = this.$store.state.webTell
    this.username = this.$store.state.name
    this.getIndex();
  },
  methods: {
    async getIndex() {

      let res = await this.$api.getIndex();
      if (res.code == 1) {
        this.list = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.list-container {
  //min-height: calc(100vh - 196px);
  min-height: $main-height;

  .cover {
    height: 240px;
    background: url("../../assets/images/banner_1@2x.png") center no-repeat;
    background-size: cover;
    position: relative;

    .cover-content {
      width: 660px;
      //height: 175px;
      @include cc;
      background: rgba(255, 255, 255, 0.30000001192092896);
      box-shadow: 0 0 10px 1px rgba(206, 206, 206, 0.49000000953674316);
      padding: 19px 65px;

      h1 {
        font-size: 19px;
        font-weight: bold;
        color: #CD281E;
        line-height: 30px;

      }

      p {
        font-size: 17px;
        color: #CD281E;
        line-height: 25px;
      }
    }
  }

  .table-area {
    width: 1200px;
    margin: 22px auto 86px;

    table {
      width: 100%;

      tr {
        height: 60px;

        td {
          vertical-align: middle;
          font-size: 16px;
          text-align: left;
          padding-left: 73px;
        }
      }

      thead {
        tr {
          background-color: $color-primary;

          td {
            color: #FFFFFF;
          }

        }
      }

      tbody {
        tr {
          cursor: pointer;

          &:nth-child(2n) {
            background-color: #F9F9F9;
          }

          td {
            &:nth-child(4) {
              color: $color-primary
            }

          }
        }
      }
    }
  }
}
</style>